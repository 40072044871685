import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pagination from '@material-ui/lab/Pagination';
import * as firebaseUtil from '../../../utils/firebase.utils';
import EditIcon from '@material-ui/icons/Edit';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { errorToast, successToast, warnToast } from 'src/toastr/toastr';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import CheckIcon from '@material-ui/icons/Check';
import AdminLogin from 'src/views/auth/AdminLogin';
import { FaCheckCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { baseUrl } from '../../../utils/constants';
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '30px'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f6b95',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
const StyledTableRow = withStyles(() => ({
  statsItem: {
    color: '#a31b35',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex'
  }
}))(TableRow);
const Results = ({
  page,
  setPage,
  className,
  doctors,
  fetchDoctors,
  handleDelete,
  updateDeletedUser,
  updateVerifiedUser,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [] = useState();
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [totalDoctorsNumber, setTotalDoctorsNumber] = useState(0);

  useEffect(() => {
    if(doctors && doctors.length){
      (async () => {
        const dt = await (
          await firebaseUtil.fbFirestore
            .collection('analysis')
            .doc('counters')
            .get()
        ).data();
        debugger
        setTotalDoctorsNumber(doctors.length);
      })();
    }
  }, [doctors]);

  const handlePageChange = async (event, value, refresh = false) => {
    debugger
    setPage(value);
    // if (value != page && !refresh) {
    //   setPage(value);
    //   // await fetchDoctors(page, 5, value < page ? true : false, refresh);
    // }
    // if (refresh) {
    //   // await fetchDoctors(page, 5, value < page ? true : false, refresh);
    // }
    // // setPage(value);
  };

  const handleEdit = id => {
    navigate(`/app/doctors/${id}`);
  };
  const disableUser = async (email, uid, disable) => {
    debugger;
    setLoading(true);
    const token = await firebaseUtil.fbAuth.currentUser.getIdToken();
    axios
      .post(
        `${baseUrl}/disableUser`,
        {
          email: email,
          disabled: !disable
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then(async res => {
        debugger;
        console.log(res);
        await firebaseUtil.fbFirestore
          .collection('users')
          .doc(uid)
          .set(
            {
              disabled: res.data.user.disabled
            },
            { merge: true }
          );
        fetchDoctors()
        handlePageChange(null, page, true);
        setLoading(false);
        successToast(
          `Successfuly ${res.data.user.disabled ? 'disabled' : 'enabled'} !`
        );
        console.log(page);
      })
      .catch(err => {
        console.log(err);
        errorToast('Could not enable/disable the user !');
      });
  };
  const verifyDoctor = async (uid, approved) => {
    setLoading(true);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(uid)
      .set({ isVerified: !approved ? true : false }, { merge: true });
    if (approved) {
      successToast('Doctor has been successfully unVerified!');
    } else {
      successToast('Doctor has been successfully verified!');
    }
    console.log(page);
    setLoading(false);
    // handlePageChange(null, page, true);
    fetchDoctors(page, 5, false, true);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <LoadingOverlay active={loading} spinner text="Loading...">
        <ToastContainer />
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Email
                  </StyledTableCell>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Phone
                  </StyledTableCell>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Verification status
                  </StyledTableCell>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Speciality
                  </StyledTableCell>
                  <StyledTableCell style={{ color: '#ffffff' }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {doctors.slice((page - 1) * limit, page * limit).map(doctor => (
                  <StyledTableRow hover key={doctor.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          className={classes.avatar}
                          src={doctor.profilePicture}
                        ></Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {doctor.firstName && doctor.firstName.length > 15
                            ? `${doctor.firstName.substr(0, 10)}...`
                            : doctor.firstName}{' '}
                          {doctor.lastName && doctor.lastName.length > 15
                            ? `${doctor.lastName.substr(0, 10)}...`
                            : doctor.lastName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <StyledTableCell>
                      {doctor.email}
                    </StyledTableCell>
                    <StyledTableCell>{doctor.phone}</StyledTableCell>
                    <StyledTableCell>
                      {doctor.isVerified ? 'Verified' : 'Not Verified'}
                    </StyledTableCell>
                    <StyledTableCell>{doctor.speciality}</StyledTableCell>

                    <StyledTableCell>
                      <IconButton onClick={() => handleEdit(doctor.id)}>
                        <EditIcon style={{ color: '#a31b35' }} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          disableUser(doctor.email, doctor.id, doctor.disabled)
                        }
                      >
                        {doctor.disabled ? (
                          <CheckIcon style={{ color: '#a31b35' }} />
                        ) : (
                          <PersonAddDisabledIcon style={{ color: '#a31b35' }} />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          verifyDoctor(doctor.id, doctor.isVerified)
                        }
                      >
                        {!doctor.isVerified ? (
                          <FaCheckCircle style={{ color: '#a31b35' }} />
                        ) : (
                          <FaTimesCircle style={{ color: '#a31b35' }} />
                        )}
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <br />
        <Pagination
          style={{ marginLeft: '400px' }}
          count={doctors && doctors.length > 5 ? Math.ceil(Math.abs(totalDoctorsNumber) / 5) : 1}
          page={page}
          onChange={handlePageChange}
        />
      </LoadingOverlay>
    </Card>
  );
};
Results.propTypes = {
  className: PropTypes.string,
  doctors: PropTypes.array.isRequired,
  handleDelete: PropTypes.func
};

export default Results;
