import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useContext, createContext, useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import * as firebaseUtil from './utils/firebase.utils';

export const userContext = createContext(null);

const App = () => {
  const [storedUser, setStoredUser] = useState(() => {
    return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : undefined;
  });

  useEffect(() => {
    firebaseUtil.fbAuth.onAuthStateChanged((user) => {
      if (user) {
        (async () => {
          const fetched = await firebaseUtil.fbFirestore
            .doc(`users/${user.uid}`)
            .get();
          const normalizedUser = {
            id: fetched.id,
            ...fetched.data()
          };
          setStoredUser(normalizedUser);
          localStorage.setItem('user', JSON.stringify(normalizedUser));
        })();
      } else {
        localStorage.removeItem('user');
        setStoredUser(undefined);
      }
    });
  }, []);
  console.log(storedUser);
  const routing = useRoutes(routes(!!storedUser));

  return (
    <ThemeProvider theme={theme}>
      <userContext.Provider value={storedUser}>
        <GlobalStyles />
        {routing}
      </userContext.Provider>
    </ThemeProvider>
  );
};

export default App;
