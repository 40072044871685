//Development
// export const baseUrl = 'https://us-central1-dfs-dev-d6efa.cloudfunctions.net'

//Production
export const baseUrl = 'https://us-central1-doctorsforsyria-bba98.cloudfunctions.net'

//Development
// export const firebaseConfig = {
//   apiKey: "AIzaSyDeVrfl4chyW-MTd4rci9JgBXa0U7tOMaI",
//   authDomain: "dfs-dev-d6efa.firebaseapp.com",
//   projectId: "dfs-dev-d6efa",
//   storageBucket: "dfs-dev-d6efa.appspot.com",
//   messagingSenderId: "523987783804",
//   appId: "1:523987783804:web:e59f325c958ea5d5cef9f9",
//   measurementId: "G-M5FY897KKG"
// };


// Production
export const firebaseConfig = {
  apiKey: "AIzaSyDZvTaESOtTSSvDEvGpri-GQaU5zfDHyHE",
  authDomain: "doctorsforsyria-bba98.firebaseapp.com",
  databaseURL: "https://doctorsforsyria-bba98.firebaseio.com",
  projectId: "doctorsforsyria-bba98",
  storageBucket: "doctorsforsyria-bba98.appspot.com",
  messagingSenderId: "424458924156",
  appId: "1:424458924156:web:a7797d03733b7232dc59f9",
  measurementId: "G-RETGZFM3H3"
};
