import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { FcClearFilters } from 'react-icons/fc';
import Page from 'src/components/Page';
import { withStyles } from '@material-ui/core/styles';
import Results from './Results';
import Toolbar from './Toolbar';
import * as firebaseUtil from '../../../utils/firebase.utils';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast, warnToast } from 'src/toastr/toastr';
import axios from 'axios';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputBase from '@material-ui/core/InputBase';
import { baseUrl } from '../../../utils/constants';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  }
}));

const CustomerListView = data => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fetchedD, setFetchedD] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedspecilites, setSelectedSpecilites] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [page, setPage] = useState(1);


  useEffect(() => {
    (async () => {
      await fetchDoctors();
    })();
  }, []);
  const fetchDoctors = async () => {
    let data;
    let mappedDoctor;
    data = await firebaseUtil.fbFirestore
      .collection('users')
      .where('type', '==', 'Doctor')
      .orderBy('email')
      .get();

    mappedDoctor = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setAllDocs(mappedDoctor);
    setFetchedD(mappedDoctor);
    setDoctors(mappedDoctor);
  };

  const fetchDoctorsBySpecilites = async s => {
    let data;
    if (verificationStatus == '') {
      data = await firebaseUtil.fbFirestore
        .collection('users')
        .where('speciality', '==', s)
        .get();
    } else {
      data = await firebaseUtil.fbFirestore
        .collection('users')
        .where('speciality', '==', s)
        .where(
          'isVerified',
          '==',
          verificationStatus == 'verified' ? true : false
        )
        .get();
    }

    const mapedDoctors = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setFetchedD(mapedDoctors);
    setAllDocs(mapedDoctors);
    setDoctors(mapedDoctors);
  };

  const fetchDoctorsByVerification = async s => {
    let data;
    if (selectedspecilites == '') {
      if (s == 'verified') {
        data = await firebaseUtil.fbFirestore
          .collection('users')
          .where('type', '==', 'Doctor')
          .where('isVerified', '==', true)
          .get();
      } else {
        data = await firebaseUtil.fbFirestore
          .collection('users')
          .where('type', '==', 'Doctor')
          .where('isVerified', '==', false)
          .get();
      }
    } else {
      if (s == 'verified') {
        data = await firebaseUtil.fbFirestore
          .collection('users')
          .where('type', '==', 'Doctor')
          .where('isVerified', '==', true)
          .where('speciality', '==', selectedspecilites)
          .get();
      } else {
        data = await firebaseUtil.fbFirestore
          .collection('users')
          .where('type', '==', 'Doctor')
          .where('isVerified', '==', false)
          .where('speciality', '==', selectedspecilites)
          .get();
      }
    }
    const mapedDoctors = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setFetchedD(mapedDoctors);
    setAllDocs(mapedDoctors);
    setDoctors(mapedDoctors);
  };

  const handlefetchDoctors = async () => {
    const data = await firebaseUtil.fbFirestore
      .collection('users')
      .where('type', '==', 'Doctor')
      .get();
    const mapedDoctors = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setFetchedD(mapedDoctors);
    setDoctors(mapedDoctors);
    setAllDocs(mapedDoctors)
    setSearchValue('');
    setSelectedSpecilites('');
    setVerificationStatus('');
  };
  const handleSpecilites = event => {
    setSelectedSpecilites(event.target.value);
    fetchDoctorsBySpecilites(event.target.value);
  };
  const handleVerified = event => {
    setVerificationStatus(event.target.value);
    fetchDoctorsByVerification(event.target.value);
  };


  const getFilteredBySearchValue = () => {
    const filtered = allDocs.filter(
      p =>
        p.firstName.toLowerCase().startsWith(searchValue.trim().toLowerCase()) ||
        p.lastName.toLowerCase().startsWith(searchValue.trim().toLowerCase()) ||
        (p.firstName.toLowerCase() + ' ' + p.lastName.toLowerCase()).startsWith(
          searchValue.trim().toLowerCase()
        ) ||
        p.phone.startsWith(searchValue.trim()) ||
        p.email.startsWith(searchValue.trim())
    );
    return filtered;
  }

  const handleFilter = ({ target: { value } }) => {
    console.log(value);
    setSearchValue(value);
    const filtered = fetchedD.filter(
      p =>
        p.firstName.toLowerCase().startsWith(value.trim().toLowerCase()) ||
        p.lastName.toLowerCase().startsWith(value.trim().toLowerCase()) ||
        (p.firstName.toLowerCase() + ' ' + p.lastName.toLowerCase()).startsWith(
          value.trim().toLowerCase()
        ) ||
        p.phone.startsWith(value.trim()) ||
        p.email.startsWith(value.trim())
    );
    setDoctors(filtered);
    console.log('filtered :', filtered);
    setPage(1);
  };
  const handleDelete = async id => {
    fetchedD.filter(p => p.id !== id);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(id)
      .delete();
    successToast(' Doctor deleted successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    await fetchDoctors();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const updateDeletedUser = async (uid, disable) => {
    let userRef = await firebaseUtil.fbFirestore
      .collection('users')
      .doc(uid)
      .get();
    doctors.filter(item => item.id !== uid);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(uid)
      .set(
        {
          disabled: disable
        },
        { merge: true }
      );
    doctors.push(userRef.data());
    setDoctors(doctors);
    await fetchDoctors();
  };
  const updateVerifiedUser = async (uid, verify) => {
    let userRef = await firebaseUtil.fbFirestore
      .collection('users')
      .doc(uid)
      .get();
    doctors.filter(item => item.id !== uid);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(uid)
      .set(
        {
          isVerified: verify
        },
        { merge: true }
      );
    doctors.push(userRef.data());
    setFetchedD(doctors);
    setDoctors(doctors);
    await fetchDoctors();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === '.') {
      e.preventDefault();
    }
  };

  const addNewDoctor = async () => {
    if (!firstName || !lastName) {
      return warnToast('Please fill in all the required fields');
    }
    if (!email) {
      return warnToast('Please insert a valid email address');
    }
    setLoading(true);
    const token = await firebaseUtil.fbAuth.currentUser.getIdToken();
    const doctorInfo = {
      email: email,
      firstName: firstName,
      lastName: lastName
    };
    axios
      .post(
        `${baseUrl}/manuallyAddDoctor`,
        {
          email: email,
          firstName: firstName,
          lastName: lastName
        },
        // {
        //   headers: {
        //     authorization: token
        //   }
        // }
      )
      .then(res => {
        handleClose();
        successToast('An email was sent to the user to complete registeration');
        setLoading(false);
        setFirstName(null);
        setLastName(null);
        setEmail(null);
      })
      .catch(err => {
        console.log(err);
        errorToast('Could not send the email to the user');
        setLoading(false);
      });
  };
  const handleDate = event => {
    setDate(event.target.value);
  };
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleChangeLastName = e => {
    setLastName(e.target.value);
  };

  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
  };
  const handleChangeEmail = e => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setEmail(e.target.value);
    }
  };

  return (
    <Page className={classes.root} title="Doctors">
      <Container maxWidth={false}>
        <Toolbar
          selectedspecilites={selectedspecilites}
          verificationStatus={verificationStatus}
          handleSpecilites={handleSpecilites}
          handleVerified={handleVerified}
          fetchDoctorsByVerification={fetchDoctorsByVerification}
          handleFilter={handleFilter}
          searchValue={searchValue}
          fetchDoctorsBySpecilites={fetchDoctorsBySpecilites}
          handlefetchDoctors={handlefetchDoctors}
        />
        <br />
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: '20%', borderRadius: '20px' }}
            onClick={handleClickOpen}
          >
            <PersonAddIcon style={{ margin: '10px 0px' }} />
            Add Doctor
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Add Doctor"
          >
            <DialogTitle id="Add Doctor" style={{ color: '#a31b35' }}>
              Add Doctor
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To add adoctor to this website, please enter some information .
              </DialogContentText>
              <Grid container direction="row" justify="space-betweren">
                <TextField
                  variant="outlined"
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#a31b35',
                    height: '50%',
                    margin: '20px',
                    width: '40%'
                  }}
                  required
                  autoFocus={true}
                  margin="dense"
                  id="name"
                  label=" First name"
                  onKeyDown={handleKeyDown}
                  type="First Name"
                  fullWidth
                  onChange={handleChangeFirstName}
                  value={firstName}
                />
                <TextField
                  variant="outlined"
                  color="#a31b35"
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#a31b35',
                    height: '50%',
                    margin: '20px',
                    width: '40%'
                  }}
                  required
                  margin="dense"
                  label="Last name"
                  onKeyDown={handleKeyDown}
                  fullWidth
                  onChange={handleChangeLastName}
                  value={lastName}
                />
                <TextField
                  variant="outlined"
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#a31b35',
                    height: '50%',
                    margin: '20px',
                    width: '90%'
                  }}
                  hintText="Email"
                  required
                  floatingLabelText="Email"
                  type="Email"
                  margin="dense"
                  label="Email address"
                  onChange={handleChangeEmail}
                />
                <br />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={addNewDoctor} disabled={loading} color="primary">
                {loading ? <CircularProgress /> : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Typography style={{ margin: '20px', color: '#20364b' }}>
          {' '}
          Viewing all Doctors{' '}
        </Typography>
        <Box mt={3}>
          <Results
            page={page}
            setPage={setPage}
            doctors={searchValue ? getFilteredBySearchValue() : doctors}
            handleDelete={handleDelete}
            fetchDoctors={fetchDoctors}
            updateDeletedUser={updateDeletedUser}
            updateVerifiedUser={updateVerifiedUser}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
