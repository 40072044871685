import * as firebase from
  'firebase';

import { firebaseConfig } from '../utils/constants'

firebase.initializeApp(firebaseConfig);

export const fbFirestore = firebase.firestore();
export const fbAuth = firebase.auth();
export const fbStorage = firebase.storage();
export const fbStorageState = firebase.storage.TaskState;
export const fbSignInWithEmailAndPassword = firebase.auth()
  .signInWithEmailAndPassword;
export const fbRegisterWithEmailAndPassword = firebase.auth()
  .createUserWithEmailAndPassword;

export const uploadImageToStorage = async (ref, file) => {
  return fbStorage.ref(ref).put(file);
};
