import React from 'react';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Toastr() {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        limit={1}
      />
    </div>
  );
}

export const successToast = (msg) => toast.success(msg);
export const warnToast = (msg) => toast.warn(msg);
export const errorToast = (msg) => toast.error(msg);

export default Toastr;
