import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pagination from '@material-ui/lab/Pagination';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@material-ui/core';
import { errorToast } from '../../../toastr/toastr';
import { successToast } from 'src/toastr/toastr';
import * as firebaseUtil from '../../../utils/firebase.utils';
import axios from 'axios';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from '../../../utils/constants';
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f6b95',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
const StyledTableRow = withStyles(() => ({
  statsItem: {
    color: '#a31b35',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex'
  },
  root: {
    '&:nth-of-type(odd)': {}
  }
}))(TableRow);
const useStyles = makeStyles(theme => ({
  root: { borderRadius: '30px' },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  patients,
  fetchPatients,
  handleDelete,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedPatientIds] = useState([]);
  const [] = useState(false);
  const [search] = useState(false);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [patientsTotalLength, setPatientsTotalLength] = useState(0);

  useEffect(() => {
    (async () => {
      const dt = await (
        await firebaseUtil.fbFirestore
          .collection('analysis')
          .doc('counters')
          .get()
      ).data();
      console.log(dt);
      setPatientsTotalLength(dt.patients || 0);
    })();
  }, []);

  const handlePageChange = async (event, value, refresh = false) => {
    debugger;
    console.log(value);
    // if (value > page) {
    //   setLimit(limit + 5);
    // }
    // else {
    //   setLimit(limit - 5)
    // }
    // setPage(value);
    if (value != page && !refresh) {
      setPage(value);
      await fetchPatients(page, 5, value < page ? true : false, refresh);
      console.log(patients.length);
    }
    if (refresh) {
      await fetchPatients(page, 5, value < page ? true : false, refresh);
    }
  };

  const handleEdit = id => {
    navigate(`/app/patients/${id}`);
  };

  const disableUser = async (email, uid, disable) => {
    setLoading(true);
    const token = await firebaseUtil.fbAuth.currentUser.getIdToken();
    axios
      .post(
        `${baseUrl}/disableUser`,
        {
          email: email,
          disabled: !disable
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then(async res => {
        debugger;
        console.log(res);
        await firebaseUtil.fbFirestore
          .collection('users')
          .doc(uid)
          .set(
            {
              disabled: res.data.user.disabled
            },
            { merge: true }
          );
        handlePageChange(null, 1, true);
        setLoading(false);
        successToast(
          `Successfuly ${res.data.user.disabled ? 'disabled' : 'enabled'} !`
        );
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        errorToast('Could not enable/disable the user !');
      });
  };

  const getFormatedDate = date => {
    console.log(date);
    if (typeof date == 'string') {
      return date;
    }
    if (date) {
      const dt = date.toDate();
      debugger;
      console.log(dt);
      if (dt) {
        return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
          '0' + dt.getDate()
        ).slice(-2)}/${dt.getFullYear()}`;
      } else {
        return date;
      }
    }
  };

  const getPagenatedPatients = () => {
    if (!patients) {
      return [];
    }
    return patients;
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <LoadingOverlay active={loading} spinner text="Loading...">
        <ToastContainer />
        {search == true ? (
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <StyledTableRow
                    onClick={() => {
                      console.log('heyyyy');
                    }}
                  >
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      DOB
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Actions
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {patients.length !== 0 &&
                    patients.slice(limit, limit + 5).map(patient => (
                      <StyledTableRow
                        hover
                        key={patient.id}
                        selected={selectedPatientIds.indexOf(patient.id) !== -1}
                      >
                        <StyledTableCell>
                          <Box alignItems="center" display="flex">
                            <Avatar
                              className={classes.avatar}
                              src={patient.profilePicture}
                            ></Avatar>
                            <Typography color="textPrimary" variant="body1">
                              {patient.firstName && patient.firstName.length > 15
                                ? `${patient.firstName.substr(0, 10)}...`
                                : patient.firstName}{' '}
                              {patient.lastName && patient.lastName.length > 15
                                ? `${patient.lastName.substr(0, 10)}...`
                                : patient.lastName}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {patient.email} {patient.disabled ? '(disabled)' : ''}
                        </StyledTableCell>
                        <StyledTableCell>
                          {' '}
                          {patient.birthDate
                            ? getFormatedDate(patient.birthDate)
                            : ''}
                        </StyledTableCell>
                        <StyledTableCell>{patient.phone}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => handleEdit(patient.id)}>
                            <EditIcon style={{ color: '#a31b35' }} />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              disableUser(
                                patient.email,
                                patient.id,
                                patient.disabled
                              )
                            }
                          >
                            {patient.disabled ? (
                              <CheckIcon style={{ color: '#a31b35' }} />
                            ) : (
                              <PersonAddDisabledIcon
                                style={{ color: '#a31b35' }}
                              />
                            )}
                          </IconButton>
                          {/* <IconButton  >

                        {(patient.disabled == false) ?
                          <PersonAddDisabledIcon onClick={() => handleDisabled(patient.id, false)} style={{ color: "#a31b35" }} />
                          :
                          <PersonAddIcon onClick={() => handleDisabled(patient.id, true)} style={{ color: "#a31b35" }} />
                        }

                      </IconButton> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <StyledTableRow
                    onClick={() => {
                      console.log('heyyyy');
                    }}
                  >
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      DOB
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ color: '#ffffff' }}>
                      Actions
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {getPagenatedPatients().map(patient => (
                    <StyledTableRow
                      hover
                      key={patient.id}
                      selected={selectedPatientIds.indexOf(patient.id) !== -1}
                    >
                      <StyledTableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar
                            className={classes.avatar}
                            src={patient.profilePicture}
                          ></Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {patient.firstName && patient.firstName.length > 15
                              ? `${patient.firstName.substr(0, 10)}...`
                              : patient.firstName}{' '}
                            {patient.lastName && patient.lastName.length > 15
                              ? `${patient.lastName.substr(0, 10)}...`
                              : patient.lastName}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {patient.email} {patient.disabled ? '(disabled)' : ''}
                      </StyledTableCell>
                      <StyledTableCell>
                        {patient.birthDate
                          ? getFormatedDate(patient.birthDate)
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell>{patient.phone}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={() => handleEdit(patient.id)}>
                          <EditIcon style={{ color: '#a31b35' }} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            disableUser(
                              patient.email,
                              patient.id,
                              patient.disabled
                            )
                          }
                        >
                          {patient.disabled ? (
                            <CheckIcon style={{ color: '#a31b35' }} />
                          ) : (
                            <PersonAddDisabledIcon style={{ color: '#a31b35' }} />
                          )}
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <br />
              <Pagination
                style={{ marginLeft: '400px' }}
                count={Math.ceil(patients.length / 5)}
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </PerfectScrollbar>
        )}
      </LoadingOverlay>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  patients: PropTypes.array.isRequired,
  handlePagination: PropTypes.func,
  handleDelete: PropTypes.func
};

export default Results;
