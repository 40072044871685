import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  TablePagination
} from '@material-ui/core';
import Page from 'src/components/Page';
import { FcContacts } from 'react-icons/fc';
import * as firebaseUtil from '../../utils/firebase.utils';
import Toastr, { errorToast } from '../../toastr/toastr';
import { useNavigate } from 'react-router-dom';
import ReactCardFlipper from '../../../node_modules/react-card-flipper';
import FreeScrollBar from '../../../node_modules/react-perfect-scrollbar';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PropTypes from 'prop-types';
import { FcPhone } from 'react-icons/fc';
import { FcInvite } from 'react-icons/fc';
import CardHeader from '@material-ui/core/CardHeader';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import Collapse from '@material-ui/core/Collapse';
import CustomCard from '../../components/card';
const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px',
    borderRadius: '20px',
    minHeight: '100%'
  },
  avatar: {
    backgroundColor: '#a31b35',
    marginRight: theme.spacing(2),
    fontSize: '40px',
    fontWeight: '50%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const Contactus = (className, data, ...rest) => {
  const [contactUs, setContactUs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);

  useEffect(() => {
    pageInit();
  }, []);

  const pageInit = async () => {
    let snapshots = await firebaseUtil.fbFirestore
      .collection('contactUs')
      .orderBy('createAt', 'desc')
      .get();
    let contactUsData = [];
    if (!snapshots.empty) {
      snapshots.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        contactUsData.push(data);
      });
    }
    console.log(contactUsData);
    setContactUs(contactUsData);
    setLoading(false);
  };
  const handleChange = (event, value) => {
    console.log(value);
    if (value > page) {
      setLimit(limit + 6);
    } else {
      setLimit(limit - 6);
    }

    setPage(value);
  };

  const getFormatedDate = date => {
    if (!date) {
      return '';
    }
    const dt = new Date(date);
    return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
      '0' + dt.getDate()
    ).slice(-2)}/${dt.getFullYear()}`;
  };

  const getMainBody = contactUs => {
    return (
      <Grid container direction="row" style={{ color: '#212121' }}>
        <Grid container direction="row">
          <EmailOutlinedIcon
            style={{
              color: '#a31b35',
              fontSize: '30px',
              marginTop: '50px',
              margin: '5px'
            }}
          />
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px'
            }}
          >
            {' '}
            {contactUs.email}{' '}
          </Typography>
        </Grid>
        <Grid container direction="row">
          {contactUs.phoneNumber ? (
            <PhoneOutlinedIcon
              style={{
                color: '#a31b35',
                fontSize: '30px',
                marginTop: '50px',
                margin: '5px'
              }}
            />
          ) : (
            ''
          )}

          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px'
            }}
          >
            {contactUs.phoneNumber}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Page className={classes.root} title="ContactUs">
      <Toastr />
      <Typography
        style={{
          color: '#20364b',
          margin: '5px',
          marginTop: '20px',
          fontSize: '25px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        {' '}
        ContactUs
      </Typography>
      <Typography style={{ margin: '20px', color: '#20364b' }}>
        {' '}
        Recent contactUs{' '}
      </Typography>

      <Grid container direction="row" justify="space-betweren">
        {contactUs.map((contactUs, index) => (
          <Grid item md={4} key={'card__' + index} style={{margin:'10px 0px'}}>
            <CustomCard
              key={'card_' + index}
              headerAvatar={{
                image: contactUs.profilePicture,
                title: contactUs.name,
                subtitle: getFormatedDate(contactUs.createdAt)
              }}
              body={getMainBody(contactUs)}
              extra={() => (
                <>
                  <div
                    style={{
                      textAlign: 'left',
                      color: '#a31b35',
                      width: 270,
                      height: 200,

                      flex: 1,
                      wordBreak: 'break-word',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <FreeScrollBar style={{ color: '#a31b35' }}>
                      <Typography
                        variant="body2"
                        component="p"
                        style={{
                          textAlign: 'left',
                          color: '#212121',
                          width: 270,
                          height: 200,
                          minHeight: 50,
                          maxHeight: 250,
                          flex: 1,
                          wordBreak: 'break-word',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {' '}
                        Message: {contactUs.message}
                      </Typography>
                    </FreeScrollBar>
                  </div>
                </>
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Container maxWidth={false} />
      <br />
      {/*<Pagination style={{ marginLeft: "400px" }} count={10} page={page} onChange={handleChange} />*/}
    </Page>
  );
};
Contactus.propTypes = {
  className: PropTypes.string,
  handlePagination: PropTypes.func
};

export default Contactus;
