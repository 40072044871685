import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FcClearFilters } from 'react-icons/fc';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid
} from '@material-ui/core';
import { FcSearch } from 'react-icons/fc';
import * as firebaseUtil from '../../../utils/firebase.utils';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: { borderRadius: '30px' },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className,
  selectedspecilites,
  verificationStatus,
  handleSpecilites,
  handleVerified,
  fetchDoctorsByVerification,
  fetchDoctorsBySpecilites,
  handlefetchDoctors,
  handleFilter,
  searchValue,
  ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [specilites, setSpecilites] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchSpecilites();
    })();
  }, []);

  const fetchSpecilites = async () => {
    let specilites = await firebaseUtil.fbFirestore
      .collection('specilites')
      .doc('specilites')
      .get();
    setSpecilites(specilites.data().specilitesList.sort((name1, name2)=>{
      console.log(name1.nameEn > name2.nameEn, name1.nameEn , name2.nameEn);
      return name1.nameEn > name2.nameEn? 1: -1;
    }
    ));
    console.log(specilites.data().specilitesList);
    console.log(specilites.data());
    setLoading(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card style={{ borderRadius: '30px' }}>
          <CardContent>
            <Grid container direction="row" justify="space-between">
              <TextField
                style={{ width: '400px',paddingBottom:'10px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="#20364b">
                        <FcSearch />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                value={searchValue}
                placeholder="Search Doctor"
                variant="outlined"
                onChange={handleFilter}
              />
              <FormControl className={classes.formControl} style={{paddingBottom:'10px'}}>
              <InputLabel style={{marginLeft:'10px'}}>Select specilities</InputLabel>
              <Select
                variant="outlined"
                style={{ width: '400px' }}
                required
                id="speciality"
                label="speciality"
                type="speciality"
                onChange={handleSpecilites}
                value={selectedspecilites}
              >
                {specilites &&
                  specilites.map(s => (
                    <MenuItem value={s.nameEn}>{s.nameEn} </MenuItem>
                  ))}
              </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
              <InputLabel style={{marginLeft:'10px'}}>Status</InputLabel>
              <Select
                variant="outlined"
                style={{ width: '150px' }}
                required
                id="verificationStatus"
                label="verificationStatus"
                type="verificationStatus"
                onChange={handleVerified}
                value={verificationStatus}
              >
                <MenuItem value={'verified'}>verified </MenuItem>
                <MenuItem value={'notVerified'}>notVerified </MenuItem>
              </Select>
              </FormControl>
              <IconButton aria-label="delete" className={classes.margin}>
              <FcClearFilters
                style={{ marginTop: '15px', fontSize: '25px' }}
                onClick={handlefetchDoctors}
              />
              </IconButton>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleFilter: PropTypes.func.isRequired
};

export default Toolbar;
