import React, { useEffect, useState } from 'react';

import { Container, makeStyles, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import * as firebaseUtil from '../../utils/firebase.utils';
import ReactCardFlipper from '../../../node_modules/react-card-flipper';
import Toastr, { errorToast, successToast } from '../../toastr/toastr';
import { FcInvite, FcPhone } from 'react-icons/fc';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import FreeScrollBar from 'react-perfect-scrollbar';
import CustomCard from '../../components/card';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '15px',
    borderRadius: '20px',
    textAlign: 'left',
    width: 320
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#a31b35'
  }
}));

const DoctorReviewers = data => {
  const classes = useStyles();
  const [doctorsReviwes, setDoctorsReviwes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    debugger;
    try {
      let doctorReviwes = [];
      let querySnapshot = await firebaseUtil.fbFirestore
        .collection('users')
        .where('type', '==', 'Doctor')
        .get();
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const element = querySnapshot.docs[i];
        console.log('doctor name : : : : :', element.data().firstName);
        let reviews = await element.ref.collection('Review').get();
        for (let i = 0; i < reviews.docs.length; i++) {
          const element2 = reviews.docs[i];
          const data = element2.data();
          data.id = element2.id;
          data.doctorName =
            element.data().firstName + ' ' + element.data().lastName;
          doctorReviwes.push(data);
        }
      }
      setDoctorsReviwes(doctorReviwes);
      console.log('doctor reviews', doctorReviwes);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getMainBody = doctorsReviwes => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ color: '#212121' }}
      >
        <Grid container direction="row" justify="space-betweren">
          {/* <EmailOutlinedIcon
            style={{
              color: '#a31b35',
              fontSize: '30px',
              marginTop: '50px',
              margin: '5px'
            }}
          /> */}
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px',
              fontWeight: 'bold'
            }}
          >
            Reviewer name: {doctorsReviwes.reviewerName}{' '}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-betweren">
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px',
              fontWeight: 'bold'
            }}
          >
            Doctor name: {doctorsReviwes.doctorName}{' '}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-betweren">
          {/* <PhoneOutlinedIcon
            style={{
              color: '#a31b35',
              fontSize: '30px',
              marginTop: '50px',
              margin: '5px'
            }}
          /> */}
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px',
              fontWeight: 'bold'
            }}
          >
            Review rating: {doctorsReviwes.rating}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Page title="Doctors Reviews">
      <Toastr />
      <Typography
        style={{
          color: '#20364b',
          margin: '5px',
          marginTop: '20px',
          fontSize: '25px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        {' '}
        Doctors Reviews
      </Typography>
      <Typography style={{ margin: '20px', color: '#20364b' }}>
        {' '}
        Recent Doctors Reviews{' '}
      </Typography>
      <Grid container direction="row" justify="space-betweren" spacing={3}>
        {doctorsReviwes.map((doctorsReviwes, index) => (
          <Grid item md={4}>
            <CustomCard
              key={'card_' + index}
              headerAvatar={{
                image: doctorsReviwes.profilePicture,
                title: doctorsReviwes.appointmentTitle
                  ? doctorsReviwes.appointmentTitle &&
                    doctorsReviwes.appointmentTitle.length > 40
                    ? `${doctorsReviwes.appointmentTitle.substr(0, 33)}...`
                    : doctorsReviwes.appointmentTitle
                  : doctorsReviwes.reviewerName
              }}
              body={getMainBody(doctorsReviwes)}
              extra={() => (
                <>
                  <Typography style={{ color: '#a31b35' }}>
                    {doctorsReviwes.appointmentTitle &&
                    doctorsReviwes.appointmentTitle.length > 40
                      ? `${doctorsReviwes.appointmentTitle.substr(0, 33)}...`
                      : doctorsReviwes.appointmentTitle}
                  </Typography>
                  <br />
                  <div
                    style={{
                      textAlign: 'left',
                      color: '#a31b35',
                      width: 270,
                      height: 200,

                      flex: 1,
                      wordBreak: 'break-word',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <FreeScrollBar style={{ color: '#a31b35' }}>
                      <Typography
                        variant="body2"
                        component="p"
                        style={{
                          textAlign: 'left',
                          color: '#212121',
                          width: 270,
                          height: 200,
                          minHeight: 50,
                          maxHeight: 250,
                          flex: 1,
                          wordBreak: 'break-word',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {' '}
                        {doctorsReviwes.comment}
                      </Typography>
                    </FreeScrollBar>
                  </div>
                </>
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Container maxWidth={false} />
      <br />
    </Page>
  );
};

export default DoctorReviewers;
