/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useNavigate } from 'react-router-dom';
/*import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';*/
import 'react-day-picker/lib/style.css';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import moment from 'moment';
import {
  TextField,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Container,
  Box
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import Profile from '../../account/AccountView/Profile';

import * as firebaseUtil from '../../../utils/firebase.utils';

import 'react-toastify/dist/ReactToastify.css';
import Paper from '@material-ui/core/Paper';
import { successToast, warnToast } from '../../../toastr/toastr';
/*
import { format } from '@telerik/kendo-intl';
*/

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.background.dark,
      minHeight: '200%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  })
);

const PatientPage = () => {
  const classes = useStyles();
  const params = useParams();
  const [patient, setPatient] = useState({});
  const [phone, setPhone] = useState();

  const [haveUnsavedEdits, setHaveUnsavedEdits] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const fetched = await firebaseUtil.fbFirestore
        .collection('users')
        .doc(params.id)
        .get();

      const {
        firstName,
        lastName,
        phone,
        email,
        birthDate,
        profilePicture
      } = fetched.data();
      // console.log(moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
      console.log(birthDate);
      setPatient({
        firstName,
        lastName,
        phone,
        email,
        birthDate: getFormatedDate(birthDate),
        profilePicture
      });
      console.log(111111, patient);
    })();
  }, []);

  const getFormatedDate = date => {
    console.log(date);
    if (typeof date == 'string') {
      return date;
    }
    if (date) {
      const dt = date.toDate();
      debugger;
      console.log(dt);
      if (dt) {
        return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
          '0' + dt.getDate()
        ).slice(-2)}/${dt.getFullYear()}`;
      } else {
        return date;
      }
    }
  };

  const handleFormSubmit = async (values, formikHelpers) => {
    formikHelpers.setSubmitting(true);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(params.id)
      .update({ ...values, birthDate: new Date(values.birthDate) });
    formikHelpers.setSubmitting(false);
    setPatient({ ...values });

    successToast('User Updated successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <ToastContainer />
        {/* <ArrowBackIcon style={{
            marginRight: '30px',marginTop: '10px', color: '#b71c1c', fontSize: '35px'
          }} onClick={() => {
            navigate('/app/patients', { replace: true });
          }}/>
          */}
        <br />
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile
              fullName={
                patient.firstName && patient.lastName
                  ? `${patient.firstName} ${patient.lastName}`
                  : ''
              }
              avatar={patient.profilePicture}
            />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Formik
              enableReinitialize
              initialValues={{
                firstName: patient.firstName || '',
                lastName: patient.lastName || '',
                email: patient.email || '',
                phone: patient.phone || '',
                birthDate: patient.birthDate || '',
                profilePicture: patient.profilePicture || ''
              }}
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                userDate,
                errors,
                formatDate,
                setFieldValue,
                touched,
                parseDate,
                handleBlur,
                handleChange,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader subheader="patient Data" title="Profile" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="firstName"
                            as={TextField}
                            variant="outlined"
                            label="First name"
                            required
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="lastName"
                            as={TextField}
                            variant="outlined"
                            label="Last name"
                            required
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="email"
                            as={TextField}
                            variant="outlined"
                            label="Email"
                            required
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="phone"
                            label={'Phone Number'}
                            component={MuiPhoneNumber}
                            variant="outlined"
                            defaultCountry="us"
                            fullWidth
                            value={patient.phone}
                            onChange={v => setFieldValue('phone', v)}
                            helperText={touched.phone ? errors.phone : ''}
                            error={touched.phone && Boolean(errors.phone)}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {/* <Field
                            name="birthdate"
                            as={TextField}
                            type="date"
                            variant="outlined"
                            label="DOB"
                            placeholder="Date of birth"
                            required
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                          /> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              name={'birthDate'}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="DOB"
                              defaultValue={
                                values.birthDate
                                  ? new Date(values.birthDate)
                                  : new Date()
                              }
                              value={
                                values.birthDate
                                  ? new Date(values.birthDate)
                                  : new Date()
                              }
                              onChange={ev => {
                                console.log(ev);
                                setFieldValue('birthDate', ev);
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              maxDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} xs={12} />
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        Save details
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PatientPage;
