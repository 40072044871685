import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import * as firebaseUtil from '../../../utils/firebase.utils';
import { successToast, warnToast } from 'src/toastr/toastr';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [fetchedP, setFetchedP] = useState([]);
  const [patients, setPatients] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const fetchPatients = async (
    page = 1,
    pageSize = 5,
    prevPage = false,
    refresh = false
  ) => {
    if (refresh) {
      setLastItem(null);
    }
    // const data = await getDataByPage(pageSize, prevPage, refresh);
    const data = await firebaseUtil.fbFirestore
    .collection('users')
    .where('type', '==', 'Patient')
    .orderBy('email')
    .get();
    debugger;
    const mapedPatients = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setFetchedP(mapedPatients);
    setPatients(mapedPatients);
    setLastItem(mapedPatients[mapedPatients.length - 1] || null);
    setFirstItem(mapedPatients[0] || null);
    console.log(mapedPatients);
  };

  const getDataByPage = async (pageSize, prev, refresh = false) => {
    if (refresh) {
      const dt = await firebaseUtil.fbFirestore
        .collection('users')
        .where('type', '==', 'Patient')
        .orderBy('email')
        .limit(pageSize)
        .startAfter(null)
        .get();
      return dt;
    }
    if (!prev) {
      const dt = await firebaseUtil.fbFirestore
        .collection('users')
        .where('type', '==', 'Patient')
        .orderBy('email')
        .limit(pageSize)
        .startAfter((lastItem && lastItem['email']) || null)
        .get();
      return dt;
    } else {
      const dt = await firebaseUtil.fbFirestore
        .collection('users')
        .where('type', '==', 'Patient')
        .orderBy('email')
        .endBefore(firstItem && firstItem['email'])
        .limitToLast(pageSize)
        .get();
      return dt;
    }
  };

  useEffect(() => {
    (async () => {
      await fetchPatients();
    })();
  }, []);

  const handleFilter = ({ target: { value } }) => {
    const filtered = fetchedP.filter(
      p =>
        p.firstName.toLowerCase().startsWith(value.trim().toLowerCase()) ||
        p.lastName.toLowerCase().startsWith(value.trim().toLowerCase()) ||
        (p.firstName.toLowerCase() + ' ' + p.lastName.toLowerCase()).startsWith(
          value.trim().toLowerCase()
        ) ||
        p.phone.startsWith(value.trim()) ||
        p.email.startsWith(value.trim())
    );
    setPatients(filtered);
  };

  const handleDelete = async id => {
    fetchedP.filter(p => p.id !== id);
    await firebaseUtil.fbFirestore
      .collection('users')
      .doc(id)
      .delete();
    successToast(' Patient deleted successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    await fetchPatients();
  };

  const handlePagination = (pageNo, pageSize) => {
    const clonedP = [...fetchedP];
    const page = clonedP.slice(pageNo * pageSize, pageNo * pageSize + pageSize);
    setPatients(page);
  };
  return (
    <Page className={classes.root} title="Patient">
      <Container maxWidth={false}>
        <Toolbar handleFilter={handleFilter} />
        <Box mt={3}>
          <Typography style={{ margin: '20px', color: '#20364b' }}>
            {' '}
            Viewing all Patient{' '}
          </Typography>
          <Results
            patients={patients}
            handleDelete={handleDelete}
            fetchPatients={fetchPatients}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
