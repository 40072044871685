import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import * as firebaseUtil from '../../utils/firebase.utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LoadingOverlay from 'react-loading-overlay';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Container,
  TextField,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogActions
} from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { errorToast, successToast, warnToast } from 'src/toastr/toastr';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import CheckIcon from '@material-ui/icons/Check';
import AdminLogin from 'src/views/auth/AdminLogin';
import { FaCheckCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { fbAuth } from 'src/utils/firebase.utils';
import { Formik } from 'formik';
import { values } from 'lodash';
import Page from 'src/components/Page';
import { baseUrl } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '30px'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f6b95',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
const StyledTableRow = withStyles(() => ({
  statsItem: {
    color: '#a31b35',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex'
  }
}))(TableRow);
const Admins = ({ }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [] = useState();
  const [allAdmins, setAllAdmins] = useState(1);
  const [page, setPage] = useState(1);
  const [admins, setAdmins] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [fetchedA, setFetchedA] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addAdminOpen, setAddAdminOpen] = useState(false);
  useEffect(() => {
    fetchAdmins();
    firebaseUtil.fbFirestore
      .collection('admins')
      .get().then((querySnapshot) => {
        setAllAdmins(querySnapshot.size);
      })
  }, []);


  const fetchAdmins = async (
    page = 1,
    pageSize = 5,
    prev = false,
    refresh = false
  ) => {
    setLoading(true);
    if (refresh) {
      setLastItem(null);
    }
    const data = await getItemsPerPage(page, pageSize, prev, refresh);
    const mappedAdmins = data.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });
    setFetchedA(mappedAdmins);
    setAdmins(mappedAdmins);
    setLastItem(mappedAdmins[mappedAdmins.length - 1] || null);
    setFirstItem(mappedAdmins[0] || null);
    setLoading(false);
  };

  const getItemsPerPage = async (page, pageSize, prev, refresh = false) => {
    const dt = await firebaseUtil.fbFirestore
      .collection('admins')
      .orderBy('email')
      .get();
    return dt;
  };

  const handlePageChange = async (event, value, refresh = false) => {
    if (value != page && !refresh) {
      setPage(value);
      await fetchAdmins(value, 5, value < page ? true : false, refresh);
    }
    if (refresh) {
      await fetchAdmins(page, 5, value < page ? true : false, refresh);
    }
  };

  const verifyAdmin = async (email, helpers) => {
    let occured;
    admins.forEach(admin => {
      if (admin.email == email) {
        occured = true;
      }
    })
    if (email == '') {
      errorToast('The Email feild should NOT be empty!');
    }
    else if (occured) {
      errorToast('The Email is already set to admin!');
    }
    else {
      setLoading(true);
      axios
        .post(
          `${baseUrl}/verifyAdmin`,
          {
            email: email
          }
        )
        .then(async res => {
          if (res.data.status == 'Success') {
            setAddAdminOpen(false)
            const doc = await firebaseUtil.fbFirestore
              .collection('users')
              .where('email', '==', email)
              .get();
            if (doc.docs.length) {
              const user = doc.docs[0].data();
              await setClaims(doc.docs[0].id, email);
              helpers.setFieldValue('email', '');
            } else {
              errorToast('Could not find the user!');
            }
            // .then(querySnapshot => {
            //   querySnapshot.forEach(doc => {
            //     console.log('doc mail : : :: :', doc.data().id);
            //     setClaims(doc.data().id, email);
            //   });
            // });
          }
          fetchAdmins();
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          console.log('test? :', err.response.data.err.code)

          if (err.response.data.err.code === 'auth/user-not-found') {
            errorToast('This account is not registered in the system');
          }
          else {
            errorToast('Fail');
          }

        });
    }
  };
  const setClaims = async (uid, email) => {
    axios
      .post(
        `${baseUrl}/setUserClaims`,
        {
          uid: uid,
          claims: {
            admin: true
          }
        }
      )
      .then(async res => {
        debugger;
        console.log(res);
        await firebaseUtil.fbFirestore
          .collection('admins')
          .doc(uid)
          .set(
            {
              isAdmin: true,
              email: email,
              uid: uid
            },
            { merge: true }
          );

        handlePageChange(null, page, true);
        successToast(`Successfuly set the user as admin!`);
        console.log(page);
      })
      .catch(err => {
        console.log(err);
        errorToast('Could not set the user as admin!');
      });
  };
  const handleRemove = uid => {
    setLoading(true);
    let user = fbAuth.currentUser;
    if (user.uid == uid) {
      errorToast("You can't remove yourself as an admin!");
      setLoading(false);
    } else {
      axios
        .post(
          `${baseUrl}/setUserClaims`,
          {
            uid: uid,
            claims: {
              admin: false
            }
          }
        )
        .then(async res => {
          debugger;
          console.log(res);
          await firebaseUtil.fbFirestore
            .collection('admins')
            .doc(uid)
            .delete();

          handlePageChange(null, page, true);
          successToast(`Successfuly removed the user as an admin!`);
          console.log(page);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          errorToast('Could not remove the user as an admin!');
        });
    }
  };

  return (
    <Page className={classes.root} title="Admins">
      <Container maxWidth="md">
        <Box my={2}>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            style={{ marginRight: '10px' }}
            onClick={() => {
              setAddAdminOpen(true)
            }}
          >
            Add Admin
          </Button>
        </Box>
        <Dialog
          open={addAdminOpen}
          onClose={() => {
            setAddAdminOpen(false)
          }}
          aria-labelledby="Add Admin"
        >
          <DialogTitle id="Add Admin" style={{ color: '#a31b35' }}>
            Add Admin
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justify="center"
            >
              <Formik
                initialValues={{
                  email: ''
                }}
                onSubmit={(values, helpers) => {
                  verifyAdmin(values.email, helpers);
                  //await fetchAdmins();
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container direction="row" justify="space-between">
                      <TextField
                        style={{ width: '500px', marginLeft: '10px' }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Write an email then click add to add the admin."
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: '10px' }}
                        >
                          Add Admin
                        </Button>
                        <Button onClick={() => {
                          setAddAdminOpen(false)
                        }} color="primary">
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </DialogContent>
        </Dialog>
        <Card className={clsx(classes.root)}>
          <LoadingOverlay active={loading} spinner text="Loading...">
            <ToastContainer />
            <PerfectScrollbar>
              <Box minWidth={1000}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ color: '#ffffff' }}>
                        Email
                      </StyledTableCell>
                      <StyledTableCell style={{ color: '#ffffff' }}>
                        Actions
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {admins.map(admin => (
                      <StyledTableRow hover key={admin.id}>
                        <StyledTableCell>{admin.email}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => handleRemove(admin.uid)}>
                            <PersonAddDisabledIcon style={{ color: '#a31b35' }} />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <br />
            {/* <Pagination
              style={{ marginLeft: '400px' }}
              count={Math.ceil(allAdmins / 5)}
              page={page}
              onChange={handlePageChange}
            /> */}
          </LoadingOverlay>
        </Card>
      </Container>
    </Page>
  );
};

export default Admins;
