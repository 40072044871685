import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import StarIcon from '@material-ui/icons/Star';
import { fbFirestore } from '../../utils/firebase.utils';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';

const LearnMore = props => {
  const [contactUs, setContactUs] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    debugger;
    fbFirestore
      .collection('contactUs')
      .doc(params.id)
      .get()
      .then(doc => {
        setContactUs(doc.data());
        console.log(doc.data());
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Card
      style={{
        height: '90%',
        width: '90%',
        borderRadius: '24px',
        padding: '2px 7px',
        fontFamily: 'HKGroteskExtraBold, sans-serif ',
        border: '2px solid "#bdbdbd"',
        fontsize: '100%',
        color: '#795548',
        textAlign: 'left',
        marginBottom: '0px',
        marginTop: '10px',
        margin: '50px'
      }}
    >
      <ArrowBackIcon
        style={{
          marginRight: '30px',
          marginTop: '10px',
          color: '#b71c1c',
          fontSize: '35px'
        }}
        onClick={() => {
          navigate('/app/contactus', { replace: true });
        }}
      />
      <Grid container direction="row" justify="center">
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ color: '#212121' }}
        >
          <Typography
            style={{
              color: 'black',
              margin: '20px',
              marginTop: '50px',
              fontSize: '25px',
              fontFamily: '"Helvetica Neue"',
              fontWeight: 'bold'
            }}
          >
            {contactUs.name}
          </Typography>
          <Typography
            style={{
              color: '#757575',
              margin: '20px',
              marginTop: '50px',
              fontSize: '20px',
              fontFamily: '"Helvetica Neue"',
              fontWeight: 'bold'
            }}
          >
            {' '}
            Phone : {contactUs.phoneNumber}{' '}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="center"
        color="textSecondary"
        component="p"
        style={{
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '20px',
          wordBreak: 'break-word'
        }}
      >
        {' '}
        {contactUs.message}{' '}
      </Typography>

      <Container maxWidth={false} />
    </Card>
  );
};

export default LearnMore;
