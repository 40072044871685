/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { successToast, warnToast } from 'src/toastr/toastr';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import {
  TextField,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Container,
  Box,
  Select,
  MenuItem
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Profile from '../../account/AccountView/Profile';
// eslint-disable-next-line import/order
import * as firebaseUtil from '../../../utils/firebase.utils';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  })
);

const DoctorPage = () => {
  const classes = useStyles();
  const params = useParams();
  const [doctor, setDoctor] = useState({});
  const [value, setValue] = useState('');
  const [specialities, setSpecialities] = useState();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [age, setAge] = useState(new Date());

  useEffect(() => {
    (async () => {
      const doctorPromise = firebaseUtil.fbFirestore
        .collection('users')
        .doc(params.id)
        .get();
      const specsPromise = firebaseUtil.fbFirestore
        .collection('specilites')
        .doc('specilites')
        .get();

      const [fetchedDoctor, fetchedSpecs] = await Promise.all([
        doctorPromise,
        specsPromise
      ]);
      const normalizedSpecs = fetchedSpecs
        .data()
        .specilitesList.map(s => s.nameEn);

      setSpecialities(normalizedSpecs);
      const {
        firstName,
        lastName,
        email,
        phone,
        description,
        birthDate,
        medicalSchool,
        languages,
        experience,
        graduationYear,
        speciality,
        profilePicture
      } = fetchedDoctor.data();
      setDoctor({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        description: description,
        birthDate: getFormatedDate(birthDate),
        medicalSchool: medicalSchool + '',
        languages: languages + '',
        experience: experience,
        graduationYear: graduationYear,
        speciality: speciality,
        profilePicture: profilePicture
      });
    })();
  }, []);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleFormSubmit = async (values, formikHelpers) => {
    var userDate = new Date(values.birthDate);
    var age = getAge(userDate);
    if (age >= 18) {
      formikHelpers.setSubmitting(true);
      console.log(
        'medical school array:::: ' + values.medicalSchool.split(',')
      );
      await firebaseUtil.fbFirestore
        .collection('users')
        .doc(params.id)
        .update({
          ...values,
          birthDate: new Date(values.birthDate),
          fullName: values.firstName + ' ' + values.lastName,
          medicalSchool: values.medicalSchool.split(','),
          languages: values.languages.split(',')
        });
      formikHelpers.setSubmitting(false);
      setDoctor({ ...values });
      successToast('User Updated successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      warnToast('Age must be above 18 years  ', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const getFormatedDate = date => {
    if (typeof date == 'string') {
      return date;
    }
    if (date) {
      const dt = date.toDate();
      if (dt) {
        console.log('DT ISISISISIS ::::::', typeof dt);
        return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
          '0' + dt.getDate()
        ).slice(-2)}/${dt.getFullYear()}`;
      } else {
        return date;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <ToastContainer />
        {/* <ArrowBackIcon style={{
          marginRight: '30px', marginTop: '10px', color: '#b71c1c', fontSize: '35px'
        }} onClick={() => {
          navigate('/app/doctors', { replace: true });
        }} />*/}
        <br />
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile
              fullName={
                doctor.firstName && doctor.lastName
                  ? `${doctor.firstName} ${doctor.lastName}`
                  : ''
              }
              avatar={doctor.profilePicture}
            />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Formik
              enableReinitialize
              initialValues={{
                firstName: doctor.firstName || '',
                lastName: doctor.lastName || '',
                email: doctor.email || '',
                phone: doctor.phone || '',
                description: doctor.description || '',
                birthDate: doctor.birthDate || '',
                medicalSchool: doctor.medicalSchool + '' || '',
                languages: doctor.languages + '' || '',
                experience: doctor.experience || '',
                graduationYear: doctor.graduationYear || '',
                speciality: doctor.speciality || '',
                profilePicture: doctor.profilePicture || ''
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().max(
                  15,
                  'Must be less than 15 characters'
                ),
                lastName: Yup.string().max(
                  15,
                  'Must be less than 15 characters'
                )
              })}
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                setFieldValue,
                touched,
                errors
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader subheader="Doctor Data" title="Profile" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <Field
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            helperText={touched.firstName && errors.firstName}
                            name="firstName"
                            as={TextField}
                            variant="outlined"
                            label="First name"
                            placeholder="First Name"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            name="lastName"
                            as={TextField}
                            variant="outlined"
                            label="Last name"
                            placeholder="Last Name"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="email"
                            as={TextField}
                            variant="outlined"
                            label="Email"
                            placeholder="Email"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="phone"
                            label={'Phone number'}
                            component={MuiPhoneNumber}
                            variant="outlined"
                            defaultCountry="us"
                            fullWidth
                            value={doctor.phone}
                            onChange={v => setFieldValue('phone', v)}
                            helperText={touched.phone ? errors.phone : ''}
                            error={touched.phone && Boolean(errors.phone)}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            name="description"
                            as={TextField}
                            variant="outlined"
                            label="Description"
                            placeholder="Description"
                            rows={4}
                            fullWidth
                            multiline
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {/*<Field*/}
                          {/*  name="birthdate"*/}
                          {/*  as={TextField}*/}
                          {/*  type="date"*/}
                          {/*  variant="outlined"*/}
                          {/*  label="DOB"*/}
                          {/*  placeholder="Date of birth"*/}
                          {/*  required*/}
                          {/*  fullWidth*/}
                          {/*  InputLabelProps={{*/}
                          {/*    shrink: true*/}

                          {/*  }}*/}
                          {/*  inputProps={{*/}
                          {/*    max:  new Date().toISOString().substring(0, 10),*/}
                          {/*  }}*/}
                          {/*/>*/}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              name={'birthDate'}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="DOB"
                              defaultValue={
                                values.birthDate
                                  ? new Date(values.birthDate)
                                  : new Date()
                              }
                              value={
                                values.birthDate
                                  ? new Date(values.birthDate)
                                  : new Date()
                              }
                              onChange={ev => {
                                console.log('from on change::', ev);
                                console.log(
                                  'from on change::2 2 2',
                                  new Date(ev)
                                );
                                const diff_ms =
                                  Date.now() - new Date(ev).getTime();
                                const age_dt = new Date(diff_ms);

                                const age = Math.abs(
                                  age_dt.getUTCFullYear() - 1970
                                );
                                if (age < 18) {
                                  return warnToast(
                                    'Age must be greater than 18 years'
                                  );
                                }
                                setFieldValue('birthDate', ev);
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              maxDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="medicalSchool"
                            title="Medical School"
                            as={TextField}
                            variant="outlined"
                            label="Medical School"
                            placeholder="Medical School"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="graduationYear"
                            as={TextField}
                            variant="outlined"
                            label="Graduation Year"
                            placeholder="Graduation Year"
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Field
                            name="languages"
                            as={TextField}
                            variant="outlined"
                            label="Languages"
                            placeholder="Languages"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="experience"
                            as={TextField}
                            variant="outlined"
                            label="Experience"
                            placeholder="Experience"
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Field
                            name="speciality"
                            as={Select}
                            variant="outlined"
                            label="Speciality"
                            placeholder="Speciality"
                            required
                            fullWidth
                          >
                            {specialities &&
                              specialities.map(s => (
                                <MenuItem value={s}>{s}</MenuItem>
                              ))}
                          </Field>
                        </Grid>

                        <Grid item md={6} xs={12} />
                      </Grid>
                    </CardContent>
                    <Divider />

                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        style={{ margin: '20px' }}
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        Save details
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DoctorPage;
