import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FcSearch } from "react-icons/fc";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles, Typography, Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: { borderRadius: '30px' },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, handleFilter, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card style={{ borderRadius: '30px' }}>

          <CardContent>
            <Box maxWidth={1000}>
              <TextField
                style={{ borderRadius: '20px' }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color='#20364b'>
                        <FcSearch />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Patient"
                variant="outlined"
                onChange={handleFilter}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleFilter: PropTypes.func.isRequired
};

export default Toolbar;
