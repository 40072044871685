import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import PatientListView from 'src/views/patient/PatientListView';
import DoctorListView from 'src/views/doctors/DoctorListView';
import DoctorPage from 'src/views/doctors/DoctorPage';
import DashboardView from 'src/views/reports/DashboardView';
// import LoginView from 'src/views/auth/LoginView';
import AdminLogin from 'src/views/auth/AdminLogin';
import Admins from 'src/views/auth/Admins';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
// import SettingsView from 'src/views/settings/SettingsView';
import FeedbackView from 'src/views/feedback/feedback';
import HighlightsView from 'src/views/highlights/highlights';
import CreateHighlightsView from 'src/views/highlights/CreateHighlight';
import PatientView from 'src/views/patient/PatientPage';
import LearnMoreView from 'src/views/feedback/learnmore';
import ContactusView from 'src/views/contact us/contactus';
import ShowMoreView from 'src/views/contact us/showmore';
import RequestsView from 'src/views/requests/requests';
import RequestView from 'src/views/requests/RequestView';
import DoctorReviewers from 'src/views/Reviewers/DoctorReviewers';
const routes = isAdmin => {
  console.log(11111111111111, isAdmin);
  return [
    {
      path: 'app',
      element: isAdmin ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'account',
          element: <AccountView />
        },

        { path: 'doctors', element: <DoctorListView /> },
        { path: 'admins', element: <Admins /> },
        { path: 'doctors/:id', element: <DoctorPage /> },
        { path: 'patients', element: <PatientListView /> },
        { path: 'patients/:id', element: <PatientView /> },
        { path: 'dashboard', element: <DashboardLayout /> },
        { path: 'feedback', element: <FeedbackView /> },
        { path: 'highlights', element: <HighlightsView /> },
        { path: 'create_highlights', element: <CreateHighlightsView /> },
        { path: 'learnmore/:id', element: <LearnMoreView /> },
        { path: 'contactus', element: <ContactusView /> },
        { path: 'requests', element: <RequestsView /> },
        { path: 'show/:id', element: <ShowMoreView /> },
        { path: 'request_show/:id', element: <RequestView /> },
        { path: 'reviewers', element: <DoctorReviewers /> },

        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: !isAdmin ? <MainLayout /> : <Navigate to="/app/dashboard" />,
      children: [
        { path: 'login', element: <AdminLogin /> },
        { path: 'register', element: <RegisterView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];
};

export default routes;
