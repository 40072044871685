import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  IconButton,
  Typography,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import * as firebaseUtil from '../../utils/firebase.utils';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import { useNavigate } from 'react-router-dom';
import Toastr, { errorToast, successToast } from '../../toastr/toastr';
import FreeScrollBar from '../../../node_modules/react-perfect-scrollbar';
import { FcLowPriority } from 'react-icons/fc';
import { FcHighPriority } from 'react-icons/fc';
import typography from '../../theme/typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  }
}));

const data = [];
const Highlights = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const getHorizontalRule = (
    <hr
      style={{
        borderRadius: '10px',
        border: '1px solid ',
        backgroundColor: '#a31b35',
        color: '#a31b35',
        width: '100%',
        marginLeft: '10px',
        margin: '20px',
        marginButton: '0px auto'
      }}
    />
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [state, setState] = React.useState(false);
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let snapshots = await firebaseUtil.fbFirestore
      .collection('highlights')
      .orderBy('createdAt', 'desc')
      .get();
    let reportsData = [];
    if (!snapshots.empty) {
      snapshots.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        reportsData.push(data);
      });
    }
    console.log(reportsData);
    setHighlights(reportsData);
    setLoading(false);
  };

  const handleDelete = async id => {
    await firebaseUtil.fbFirestore
      .collection('highlights')
      .doc(id)
      .delete()
      .then(() => {
        successToast('Successfully Deleted the post');
        console.log(errorToast);
        fetchData();
      })
      .catch(err => {
        errorToast('Could not delete the highlight');
      });
  };
  const handlePublised = async (id, status) => {
    console.log(id, !status);
    await firebaseUtil.fbFirestore
      .collection('highlights')
      .doc(id)
      .set(
        {
          published: !status
        },
        { merge: true }
      )
      .then(() => {
        fetchData();
      });
  };

  return (
    <Page title="Highlights">
      <Toastr />

      <Typography
        style={{
          color: '#20364b',
          margin: '5px',
          marginTop: '20px',
          fontSize: '25px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        Highlights
      </Typography>
      <Button
        onClick={() => {
          navigate('/app/create_highlights', { replace: true });
        }}
        style={{
          marginTop: '20px',
          margin: '20px',
          borderRadius: '50px',
          padding: '10px 7px',
          fontFamily: 'HKGroteskExtraBold, Segoe UI Emoji ',
          border: '2px solid #e1f5fe',
          backgroundColor: '#e1f5fe',
          fontsize: '50%',
          color: '#0288d1',
          textAlign: 'center'
        }}
      >
        <AddIcon />
        create highlight
      </Button>
      <Grid container direction="row" justify="space-betweren">
        <Grid container direction="row" justify="space-betweren">
          {getHorizontalRule}
        </Grid>
        {highlights.map(highlights => (
          <Card style={{ maxWidth: '21%', height: '20%', margin: '20px' }}>
            <CardActionArea>
              <CardMedia>
                <img
                  style={{
                    height: 200,
                    maxWidth: 1000,
                    margin: 'auto',
                    marginRight: '20px'
                  }}
                  src={highlights.img}
                />
              </CardMedia>
              <CardContent>
                <Typography
                  style={{
                    color: '#20364b',
                    fontSize: '20px',
                    fontFamily: '"Helvetica Neue"',
                    fontWeight: 'bold',
                    maxWidth: '100%',
                    textAlign: 'left',
                    wordBreak: 'break-word'
                  }}
                >
                  {' '}
                  {highlights.title && highlights.title.length >= 40
                    ? `${highlights.title.substr(0, 30)}...`
                    : highlights.title}
                </Typography>
                <br />
                <div
                  style={{
                    textAlign: 'left',
                    color: '#a31b35',
                    width: 200,
                    height: 200,
                    flex: 1,
                    wordBreak: 'break-word',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FreeScrollBar style={{ color: '#a31b35' }}>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{
                        textAlign: 'left',
                        color: '#212121',
                        width: 200,
                        height: 200,
                        minHeight: 50,
                        maxHeight: 250,
                        flex: 1,
                        wordBreak: 'break-word',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      {highlights.description}
                    </Typography>
                  </FreeScrollBar>
                </div>
              </CardContent>
            </CardActionArea>
            <br />
            <br />
            <CardActions>
              <Button
                onClick={() => handleDelete(highlights.id)}
                size="small"
                style={{ color: '#a31b35' }}
              >
                delete post
              </Button>

              <IconButton>
                {highlights.published == false ? (
                  <FcHighPriority
                    onClick={() => handlePublised(highlights.id, false)}
                    style={{ color: '#a31b35' }}
                  />
                ) : (
                  <FcLowPriority
                    onClick={() => handlePublised(highlights.id, true)}
                    style={{ color: '#a31b35' }}
                  />
                )}
              </IconButton>
            </CardActions>
          </Card>
        ))}
        <Container maxWidth={false} />
      </Grid>
    </Page>
  );
};

export default Highlights;
