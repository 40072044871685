import React from 'react';
import {
  Box,
  Container,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Toastr, { errorToast } from '../../toastr/toastr';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import Page from 'src/components/Page';
import * as firebaseUtil from '../../utils/firebase.utils';
import { values } from 'lodash';
import { baseUrl } from '../../utils/constants';

const useStyles = makeStyles(theme =>
  // eslint-disable-next-line implicit-arrow-linebreak
  createStyles({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  })
);

const AdminLogin = () => {
  const classes = useStyles();
  const verifyAdmin = async (values, formikHelpers) => {
    axios
      .post(
        `${baseUrl}/verifyAdmin`,
        {
          email: values.email
        }
      )
      .then(async res => {
        debugger;
        if (res.data.status == 'Success' && res.data.data.admin) {
          formikHelpers.setSubmitting(true);
          try {
            await firebaseUtil.fbAuth.signInWithEmailAndPassword(
              values.email,
              values.password
            );
          } catch (e) {
            console.log(e);
            if (e.code === 'auth/user-not-found') {
              formikHelpers.setErrors({ email: 'user not found' });
            }
            if (e.code === 'auth/wrong-password') {
              formikHelpers.setErrors({ password: 'incorrect password' });
            }
            formikHelpers.setSubmitting(false);
          }
        } else {
          errorToast("You don't have the permissions to login.");
        }
      })
      .catch(err => {
        console.log(err);
        errorToast("You don't have the permissions to login.");
      });
  };
  return (
    <Page className={classes.root} title="Login">
      <Toastr />
      <Box
        maxWidth="60%"
        marginLeft="400px"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container style={{ color: '#3f6b95' }} maxWidth="40%">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={async (values, formikHelpers) =>
              await verifyAdmin(values, formikHelpers)
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                style={{ backgroundColor: '#3f6b95', width: '80%' }}
                onSubmit={handleSubmit}
              >
                <Box mb={3}>
                  <br />

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{
                      fontSize: '15px',
                      margin: '20px',
                      color: '#f5f5f5'
                    }}
                  >
                    Username or Email address
                  </Typography>

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    style={{
                      width: '90%',
                      margin: '20px',
                      backgroundColor: '#f5f5f5'
                    }}
                    helperText={touched.email && errors.email}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{
                      fontSize: '15px',
                      margin: '20px',
                      color: '#f5f5f5'
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    style={{
                      width: '90%',
                      margin: '20px',
                      backgroundColor: '#f5f5f5'
                    }}
                    helperText={touched.password && errors.password}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Box>
                <Box my={2}>
                  <Button
                    style={{ width: '90%', margin: '20px' }}
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default AdminLogin;
