import React, { useState } from 'react';
import { Container, makeStyles, Typography, Grid } from '@material-ui/core';
import { TextArea } from 'semantic-ui-react';

import Page from 'src/components/Page';
import * as firebaseUtil from '../../utils/firebase.utils';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import highlights from './highlights';
import { useNavigate } from 'react-router-dom';
import { successToast, warnToast } from 'src/toastr/toastr';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateHighlight = ({ className, ...rest }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const getHorizontalRule = (
    <hr
      style={{
        borderRadius: '10px',
        border: '1px solid ',
        backgroundColor: '#757575',
        color: '#757575',
        width: '85%',
        marginLeft: '20px',
        margin: '20px',
        marginButton: '0px auto'
      }}
    />
  );
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [published, setPublished] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleSave = async () => {
    if (!image || !description || !title) {
      return warnToast('Please fill all details');
    }
    await firebaseUtil.fbFirestore
      .collection('highlights')
      .doc()
      .set(
        {
          title: title,
          description: description,
          published: published,
          img: image,
          createdAt: new Date().getTime()
        },
        { merge: true }
      )
      .then(() => {
        return navigate('/app/highlights');
      });
  };
  const handleChangeTitle = e => {
    if (e.target.value.length <= 100) {
      setTitle(e.target.value);
    }
  };
  const handleChangeDescription = e => {
    if (e.target.value.length <= 450) {
      setDescription(e.target.value);
    }
  };
  const handlePublished = async e => {
    if (!published) {
      setPublished(!published);
    }
  };

  const uploadPic = e => {
    let file = e.target.files[0];
    var uploadTask = firebaseUtil.fbStorage
      .ref(`highlights/${new Date().getTime()}${file.name}`)
      .put(file);
    setUploading(true);
    uploadTask.on(
      'state_changed',
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebaseUtil.fbStorageState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebaseUtil.fbStorageState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
        setUploading(false);
      },
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        successToast('The picture uploaded successfully');
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL);
          setUploading(false);
          setImage(downloadURL);
        });
      }
    );
  };

  return (
    <Page className={classes.root} title="highlight">
      <ToastContainer />

      <Typography
        style={{
          color: 'black',
          margin: '18px',
          fontSize: '23px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        {/* <ArrowBackIcon style={{
          marginRight: '15px', color: '#a31b35', fontSize: '32px'
        }} onClick={() => {
          navigate('/app/highlights', { replace: true });
        }} />*/}
        Start creating your highlight
      </Typography>
      <Typography
        style={{
          color: '#757575',
          margin: '18px',
          fontSize: '20px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        To create a highlight follow the necessary field,checking over your
        text.
      </Typography>
      <Typography
        style={{
          color: 'black',
          margin: '20px',
          fontSize: '20px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        Title of highlight
      </Typography>

      <TextArea
        rows={2}
        style={{ width: '60%', margin: '20px', minHeight: 70, padding: '10px' }}
        required
        onChange={handleChangeTitle}
        value={title}
      />
      <Typography
        style={{
          color: title.length < 100 ? 'black' : 'red',
          fontSize: '12px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold',
          marginLeft: '20px',
          marginTop: '-20px'
        }}
      >
        {title.length}/100
      </Typography>
      <Typography
        style={{
          color: 'black',
          margin: '18px',
          fontSize: '20px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        Description
      </Typography>
      <TextArea
        style={{
          margin: '20px',
          minHeight: 200,
          width: '60%',
          padding: '10px'
        }}
        required
        onChange={handleChangeDescription}
        value={description}
      />
      <Typography
        style={{
          color: description.length < 450 ? 'black' : 'red',
          fontSize: '12px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold',
          marginLeft: '20px',
          marginTop: '-20px'
        }}
      >
        {description.length}/450
      </Typography>
      <Typography
        style={{
          color: 'black',
          margin: '10px',
          fontSize: '20px',
          fontFamily: '"Helvetica Neue"',
          fontWeight: 'bold'
        }}
      >
        Do you want to publish the highlight?
      </Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
        >
          <FormControlLabel
            value="start"
            style={{ margin: '20px' }}
            onClick={handlePublished}
            control={<Radio color="primary" />}
            label="Published"
            labelPlacement="published"
          />
          <FormControlLabel
            value="bottom"
            style={{ margin: '20px' }}
            control={<Radio color="primary" />}
            label="Not Published"
            labelPlacement="Not published"
          />
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      {image ? (
        <img
          style={{ margin: '20px' }}
          src={image}
          width="250px"
          height="250px"
        />
      ) : null}

      {uploading ? (
        <Typography
          style={{
            color: '#757575',
            margin: '18px',
            fontSize: '15px',
            fontFamily: '"Helvetica Neue"'
          }}
        >
          Uploading ...
        </Typography>
      ) : null}
      <input
        style={{
          color: '#757575',
          margin: '18px',
          fontSize: '15px',
          fontFamily: '"Helvetica Neue"'
        }}
        id="profilePic"
        accept="image/*"
        type="file"
        onChange={uploadPic}
        required
        disabled={uploading}
      />
      <Typography
        style={{
          color: '#757575',
          fontSize: '15px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        Supported file type: JPG, JPEG, PNG
      </Typography>
      <br />
      <br />

      <Button
        style={{
          height: '30%',
          marginTop: '20px',
          margin: '20px',
          padding: '5px 7px',
          border: '2px solid #a31b35',
          backgroundColor: '#a31b35',
          fontsize: '30%',
          color: '#f5f5f5',
          textAlign: 'center'
        }}
        onClick={handleSave}
        disabled={uploading}
      >
        save post
      </Button>

      <Container maxWidth={false} />
    </Page>
  );
};

export default CreateHighlight;
