import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  CardActions,
  CardActionArea
} from '@material-ui/core';
import Page from 'src/components/Page';
import { FcFeedback } from 'react-icons/fc';
import ReactCardFlipper from '../../../node_modules/react-card-flipper';
import { FcInvite } from 'react-icons/fc';
import { FcPhone } from 'react-icons/fc';
import Toastr, { errorToast, successToast } from '../../toastr/toastr';
import * as firebaseUtil from '../../utils/firebase.utils';
import FreeScrollBar from '../../../node_modules/react-perfect-scrollbar';
import { useNavigate } from 'react-router';
import Fade from 'react-reveal/Fade';
import CustomCard from '../../components/card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    avatar: {
      marginRight: theme.spacing(2),
      fontSize: '40px',
      fontWeight: '50%'
    }
  },
  card: {
    margin: '20px',
    width: '29%'
  },
  taskOwner: {
    display: 'flex',
    width: '5rem'
  }
}));

const Feedback = data => {
  const classes = useStyles();
  const [feedback, setFeedback] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [openedDialog, setOpenedDialog] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event, value) => {
    console.log(value);
    if (value > page) {
      setLimit(limit + 6);
    } else {
      setLimit(limit - 6);
    }
    setPage(value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const snapshots = await firebaseUtil.fbFirestore
      .collection('feedback')
      .orderBy('createdAt', 'desc')
      .get();
    const feedbackData = [];
    debugger;
    if (!snapshots.empty) {
      snapshots.docs.forEach(doc => {
        const data = doc.data();
        data.id = doc.id;
        feedbackData.push(data);
      });
    }
    console.log(feedbackData);
    setFeedback(feedbackData);
    setLoading(false);
  };
  const learnMore = id => {
    return navigate(`/app/learnmore/${id}`);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDelete = async id => {
    await firebaseUtil.fbFirestore
      .collection('feedback')
      .doc(id)
      .delete()
      .then(() => {
        successToast('Successfully Deleted The Feedback');
        console.log(errorToast);
        fetchData();
      })
      .catch(err => {
        console.log(err);
        errorToast('Could not delete');
      });
  };

  const getMainBody = _feedback => {
    return (
      <>
        <div>
          <Typography
            style={{
              color: '#101010'
            }}
          >
            Name: {_feedback.name}
          </Typography>
        </div>
        <br />
        <div>
          <Typography
            style={{
              color: '#101010'
            }}
          >
            Created at: {getFormatedDate(_feedback.createdAt)}
          </Typography>
        </div>
        <br />
        <div>
          <Typography
            style={{
              color: '#101010'
            }}
          >
            <FcInvite
              style={{
                color: '#101010',
                fontSize: '30px'
              }}
            />
            &nbsp;&nbsp;
            {_feedback.email}
          </Typography>
        </div>
        <br />
        <div>
          <Typography
            style={{
              color: '#101010'
            }}
          >
            <FcPhone
              style={{
                color: '#101010',
                fontSize: '30px'
              }}
            />
            &nbsp;&nbsp;
            {_feedback.phone}
          </Typography>
        </div>
        <br />
      </>
    );
  };

  const getFormatedDate = date => {
    if (!date) {
      return '';
    }
    const dt = new Date(date);
    return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
      '0' + dt.getDate()
    ).slice(-2)}/${dt.getFullYear()}`;
  };

  return (
    <Page className={classes.root} title="Feedback">
      <Toastr />
      <Typography
        style={{
          color: '#20364b',
          margin: '5px',
          marginTop: '20px',
          fontSize: '25px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        {' '}
        Feedback
      </Typography>
      <Typography style={{ margin: '20px', color: '#20364b' }}>
        {' '}
        Recent Feedback{' '}
      </Typography>
      <Grid container direction="row" justify="space-betweren" spaceing={4}>
        {feedback.map((_feedback, index) => (
          <Grid item md={4} spacing={4} style={{ marginTop: '20px' }}>
            <CustomCard
              key={'cardx_' + index}
              headerAvatar={{
                image: _feedback.profilePicture,
                title: _feedback.title,
                subtitle: getFormatedDate(_feedback.createdAt)
              }}
              body={getMainBody(_feedback)}
              extra={() => (
                <>
                  <Typography
                    style={{
                      color: '#20364b',
                      fontSize: '20px',
                      fontFamily: '"Helvetica Neue"'
                    }}
                  >
                    Title:{' '}
                    {_feedback.title && _feedback.title.length > 40
                      ? `${_feedback.title.substr(0, 33)}...`
                      : _feedback.title}
                  </Typography>
                  <FreeScrollBar style={{ color: '#a31b35' }}>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{
                        textAlign: 'left',
                        color: '#212121',
                        width: 270,
                        height: 100,
                        minHeight: 50,
                        maxHeight: 250,
                        flex: 1,
                        wordBreak: 'break-word',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      Feedback: {_feedback.feedback}
                    </Typography>
                  </FreeScrollBar>
                </>
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Container maxWidth={false} />
      <br />
    </Page>
  );
};

export default Feedback;
