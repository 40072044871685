import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  CardActions,
  Avatar,
  TablePagination,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import Page from 'src/components/Page';
import * as firebaseUtil from '../../utils/firebase.utils';
import { fbFirestore } from '../../utils/firebase.utils';
import Toastr, { errorToast } from '../../toastr/toastr';
import { useNavigate } from 'react-router-dom';
import ReactCardFlipper from '../../../node_modules/react-card-flipper';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { FcClearFilters } from 'react-icons/fc';
import FreeScrollBar from 'react-perfect-scrollbar';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import Collapse from '@material-ui/core/Collapse';
import CustomCard from '../../components/card';
import firebase from 'firebase';
import { FcSearch } from 'react-icons/fc';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0)
  },
  extendedIcon: {
    marginRight: theme.spacing(0)
  },
  taskBudgetStatus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
    minHeight: '3rem'
  },
  root: {
    textAlign: 'left',
    width: 320,
    margin: '10px',
    borderRadius: '20px',
    minHeight: '100%',
    avatar: {
      marginRight: theme.spacing(2),
      fontSize: '40px',
      fontWeight: '50%'
    }
  },
  taskOwner: {
    display: 'flex',
    width: '5rem'
  },

  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#a31b35'
  }
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    margin: '16px',
    width: '90%',
    fontSize: '10px',
    fontFamily: '"Helvetica Neue"',
    textAlign: 'left',
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 15,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const Requests = (className, data, ...rest) => {
  const [requests, setRequests] = useState([]);
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const [users, setUsers] = useState([data]);
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getRequests();
    getUsers();
    handleGetRequest();
    //getPatientData();
  }, []);
  const getRequests = async () => {
    debugger;
    setLoading(true);
    let snapshots = await firebaseUtil.fbFirestore
      .collection('appointments')
      .where('status', '==', 0)
      .get();
    console.log(snapshots.data);
    let requestsData = [];
    if (!snapshots.empty) {
      snapshots.docs.forEach(async doc => {
        let data = doc.data();
        let patientData = await firebaseUtil.fbFirestore
          .collection('users')
          .doc(doc.data().uid)
          .get();
        data.id = doc.id;
        console.log('patient email ', patientData?.data()?.email);
        data.email = patientData?.data()?.email || '';
        data.phone = patientData?.data()?.phone || '';
        requestsData.push(data);
      });
    }
    console.log(requestsData);
    setRequests(requestsData);
    setLoading(false);
  };

  const getUsers = async () => {
    let snapshots = await firebaseUtil.fbFirestore
      .collection('users')
      .where('type', '==', 'Doctor')
      .get();
    let usersData = [];
    if (!snapshots.empty) {
      snapshots.docs.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        usersData.push(data);
      });
    }
    console.log(usersData);
    setUsers(usersData);
    setValue('');

    setLoading(false);
  };
  const ShowMore = id => {
    return navigate('/app/request_show/' + id);
  };
  const handleChange = (event, value) => {
    console.log(value);
    if (value > page) {
      setLimit(limit + 6);
    } else {
      setLimit(limit - 6);
    }
    setPage(value);
  };
  const handleGetRequest = async (event, value) => {
    let snapshots = await firebaseUtil.fbFirestore
      .collection('appointments')
      .where('status', '==', 0)
      .get();
    let requestsData = [];
    if (!snapshots.empty) {
      snapshots.docs.forEach(async doc => {
        let data = doc.data();
        data.id = doc.id;
        requestsData.push(data);
      });
    }
    console.log(requestsData);
    setSearch(false);
    setRequests(requestsData);
    setLoading(false);
    setValue('');
  };
  const handleFilter = ({ target: { value } }) => {
    if (value == '') {
      handleGetRequest();
    }
    console.log(value);
    const filtered = requests.filter(p =>
      p.doctorName.toLowerCase().startsWith(value.trim().toLowerCase())
    );
    setRequests(filtered);
    setSearchValue(value);
  };
  const handleUsers = async id => {
    debugger;
    // the id is a variable so we need to read it from the parameter not as string like 'id', Okay ?
    let snapshots = await firebaseUtil.fbFirestore
      .collection('appointments')
      .where('doctorId', '==', id)
      .where('status', '==', 0)
      .get();

    let requestsData = [];
    if (!snapshots.empty) {
      snapshots.docs.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        requestsData.push(data);
      });
    }
    console.log(requestsData);
    setRequests(requestsData);
    setSearch(true);
    setLoading(false);
  };

  const handleDoctorsName = async id => {
    let snapshots = await firebaseUtil.fbFirestore
      .collection('appointments')
      .where(id, '==', 'doctorId')
      .get();

    let requestsData = [];
    if (!snapshots.empty) {
      snapshots.docs.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        requestsData.push(data);
      });
    }
    console.log(requestsData);
    setRequests(requestsData);
    setSearch(true);
    setLoading(false);
  };

  const handleValue = event => {
    setValue(event.target.value);
    handleUsers(event.target.value);
  };

  const getMainBody = request => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ color: '#212121' }}
      >
        <Grid container direction="row" justify="space-betweren">
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px'
            }}
          >
            Patient Name: {request.patientName}{' '}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-betweren">
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px'
            }}
          >
            Doctor Name: {request.doctorName}{' '}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="space-betweren">
          <Typography
            style={{
              color: '#212121',
              fontSize: '15px',
              margin: '10px'
            }}
          >
            Date of call: {getFormatedDate(request.dateOfCall)}{' '}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getFormatedDate = date => {
    if (date) {
      const dt = date.toDate();
      if (dt) {
        return `${('0' + (dt.getMonth() + 1)).slice(-2)}/${(
          '0' + dt.getDate()
        ).slice(-2)}/${dt.getFullYear()}`;
      } else {
        return date;
      }
    }
  };

  return (
    <Page title="Requests">
      <Toastr />
      <Typography
        style={{
          color: '#20364b',
          margin: '5px',
          fontSize: '25px',
          marginTop: '20px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        {' '}
        Requests
      </Typography>
      <Typography
        style={{
          color: '#a31b35',
          margin: '10px',
          fontSize: '18px',
          fontFamily: '"Helvetica Neue"'
        }}
      >
        {' '}
        Select Doctor
      </Typography>
      <Card style={{ borderRadius: '30px' }}>
        <CardContent>
          <Grid container direction="row" justify="space-between">
            <TextField
              style={{ width: '400px', marginBottom:'10px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="#20364b">
                      <FcSearch />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              value={searchValue}
              placeholder="Search Doctor"
              variant="outlined"
              onChange={handleFilter}
            />
            <FormControl className={classes.formControl}>
              <InputLabel style={{marginLeft:'10px'}}>Select doctor</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={value}
              onChange={handleValue}
              variant="outlined"
              style={{ width: '400px' }}
            >
              {users.map(user => (
                <MenuItem value={user.id} onChange={() => handleUsers(user.id)}>
                  {user.firstName} {user.lastName}{' '}
                </MenuItem>
              ))}
            </Select>

            </FormControl>

            <IconButton aria-label="delete" className={classes.margin}>
              <FcClearFilters
                style={{ fontSize: '25px' }}
                onClick={handleGetRequest}
              />
            </IconButton>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Grid container direction="row" justify="space-betweren" spacing={3}>
        {requests.map((request, index) => (
          <Grid item md={4} spacing={3}>
            <CustomCard
              key={'cardy_' + index}
              headerAvatar={{
                image: request.image,
                title: request.patientName,
                subtitle: getFormatedDate(request.createAt)
              }}
              body={getMainBody(request)}
              extra={() => (
                <>
                  <Typography style={{ color: '#a31b35', fontWeight: 'bold' }}>
                    Title:{' '}
                    {request.caseDescription &&
                    request.caseDescription.length > 40
                      ? `${request.caseDescription.substr(0, 33)}...`
                      : request.caseDescription}
                  </Typography>
                  <br />
                  <div
                    style={{
                      textAlign: 'left',
                      color: '#a31b35',
                      width: 270,
                      height: 200,

                      flex: 1,
                      wordBreak: 'break-word',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <FreeScrollBar style={{ color: '#a31b35' }}>
                      <Typography
                        variant="body2"
                        component="p"
                        style={{
                          textAlign: 'left',
                          color: '#212121',
                          width: 270,
                          height: 200,
                          minHeight: 50,
                          maxHeight: 250,
                          flex: 1,
                          wordBreak: 'break-word',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        {' '}
                        Case Description: {request.caseDescription}
                      </Typography>
                    </FreeScrollBar>
                  </div>
                </>
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Container maxWidth={false} />
      <br />
      {/* <Pagination style={{ marginLeft: "400px" }} count={10} page={page} onChange={handleChange} />*/}
    </Page>
  );
};
export default Requests;
